/* src/styles.css */

/* Основные стили для всего скроллбара */
::-webkit-scrollbar {
  width: 12px; /* ширина для вертикального скроллбара */
  height: 12px; /* высота для горизонтального скроллбара */
}

/* Фоновый стиль для скроллбара */
::-webkit-scrollbar-track {
  background: #000000; /* цвет фона скроллбара */
  border-radius: 10px; /* радиус скругления углов */
}

/* Стиль для ползунка скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #1C1C1D; /* цвет ползунка */
  border-radius: 10px; /* радиус скругления углов ползунка */
  border: 3px solid #000000; /* рамка вокруг ползунка */
}

/* При наведении курсора на ползунок */
::-webkit-scrollbar-thumb:hover {
  background-color: #131314; /* цвет ползунка при наведении */
}

.custom-button {
    padding: 12px;
    margin: 8px;
    background: #007AFF;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 8px;
    transition: background 0.3s, transform 0.3s;
  }
  
  .custom-button:hover {
    background: #005BB5;
    transform: scale(1.05);
  }
  
  .custom-button:active {
    background: #003D80;
  }
  